import IdentificaImportacao from "../../../../components/importacaoProdutos/IdentificaImportacao.vue";
import UploadFiles from "../../../../components/importacaoProdutos/UploadFiles.vue";
import { tabelaClientes } from "./tabelaClientes.js";
import CardImportacao from "../../../../components/importacaoClientes/CardImportacao.vue";
import ReviewImport from '../../../../components/importacaoClientes/ReviewImport.vue';

export default {
  components: {
    IdentificaImportacao,
    UploadFiles,
    CardImportacao,
    ReviewImport,
  },

  data() {
    return {
      keyImportacao: 1,
      step: 1,
      identificador: {},
      tipoArquivoXLSX: "cliente",
      nomeArquivo: "SAAS - Modelo importação de cliente (v.1.0.0).xlsx",
      versaoArquivo: "v.1.0.0",
      tabelaClientesBase64: tabelaClientes,
      dados: [],
      attUpload: 0,
      options: [
        { 
          id: "Corretos", 
          image: "/corretos.svg", 
          color: "#22c55e", 
          dados: [], 
        },
        {
          id: "Incorretos",
          image: "/incorretos.svg",
          color: "#ef4444",
          dados: [],
        },
        {
          id: "Duplicados",
          image: "/duplicados.svg",
          color: "#f59e0b",
          dados: [],
        },
      ],
      titulo: "Importação de Clientes",
    }
  },

  methods: {

    backImport() {
      this.step--;
    },

    ultimoPasso(){
      this.step++
    },

    identificaLote(identificacao){
      this.identificador = identificacao;
      this.step++;
    },

    importXLSX(dados) {
      this.dados = dados;
      this.consistir(this.dados);
      this.step = 3;
    },

    consistir(dados) {
      let prods = dados;
      prods = this.ValidarDados(prods);

      this.options[0].dados = [];
      this.options[1].dados = [];
      this.options[2].dados = [];
      prods.forEach( (item) => {
        if (item.situacao == "OK") {
          this.options[0].dados.push(item);
        } else if (item.situacao == "DUPLICADOS") {
          this.options[2].dados.push(item);
        } else {
          this.options[1].dados.push(item);
        }
      });
    },
    
    ValidarDados(dados) {
      let arrayDados = dados;
      arrayDados.forEach((item) => {
        if ( (item.tp_pessoa) && (item.nr_cpfcnpj) && (item.cd_situac) && (item.nm_client) ) {
          item.situacao = "OK";
        } else {
          item.situacao = "";
          if (!item.tp_pessoa) {
            item.situacao += (item.situacao == ""? "": ",") + "Tipo de pessoa";
          }
          if (!item.nr_cpfcnpj) {
            item.situacao += (item.situacao == ""? "": ",") + "CPF/CNPJ";
          }
          if (!item.cd_situac) {
            item.situacao += (item.situacao == ""? "": ",") + "Situação";
          }
          if (!item.nm_client) {
            item.situacao += (item.situacao == ""? "": ",") + "Nome do cliente";
          }          
        }
      
      });
      return arrayDados;
    },

    salvar( dados ) {
    },

    atualizarDados( dados ) {
      //-- Carregar os dados carregados/alterados da tela
      if (dados.id == "Corretos") {
        this.options[0].dados = dados.dados;
      } else if (dados.id == "Incorretos") {
        this.options[1].dados = dados.dados;
      } else if (dados.id == "Duplicados") {
        this.options[2].dados = dados.dados;
      } 

      //-- Unir todos os dados (Corretos, Incorretos e Duplicados) e consistir os dados novamente
      let prods = this.options[0].dados;
      this.options[1].dados.forEach( (item) => { prods.push(item); });      
      this.options[2].dados.forEach( (item) => { prods.push(item); });      
      prods = this.consistir(prods);
    },

    mudaIdentificacao(novaIdentificacao){
      this.identificador = novaIdentificacao;
    },    

    async confirmarImportacao() {
      try {
        this.startLoader();
        let usuario = await this.getUser();
        let params = {
          cd_loja: this.identificador.loja,
          id_guid: this.uuidv4(),
          dt_hora: this.identificador.hora,
          ds_desc: this.identificador.desc,
          ds_obs:  this.identificador.obs,
          js_cli:  await this.getClientes(),
          cd_oper: usuario.base.us01_nr_usr,
        };
        let retorno;

        retorno = await this.request({
          metodo: "post",
          sp: "sp_ce360901044",
          params: params,
        });
        if (retorno.data.database[0].sp_ce360901044 == "OK") {
          this.notification().success({mensagem: "Cliente(s) importado(s) com sucesso!"});
          this.$router.push({ path: '/saastec-dashboard' });
        } else {
          // this.notification().error({erro: "Algo deu errado no envio do(s) produto(s). "+retorno.data.database[0].sp_ce360901038});
          this.notification().warn({mensagem: retorno.data.database[0].sp_ce360901044});
          // eslint-disable-next-line no-console
        }
      } catch (e) {
        this.notification().error({erro: "Algo deu errado no envio do(s) clientes(s). "+e});
        // eslint-disable-next-line no-console
        this.mensagemConsole("ERRO#02: ", e);
      } finally {
        this.stopLoader();
      }      
    },    

    async getClientes() {
      return this.options[0].dados;
    },    

  },
};
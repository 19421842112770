<template>
  <v-card
    v-if="
      cliente != null && cliente != undefined && Object.keys(cliente).length
    "
    color="transparent"
    :style="{
      border: $vuetify.theme.dark
        ? '1px solid #555 !important'
        : '1px solid #ddd !important',
      height: '688px',
      overflowY: 'auto',
      scrollBehavior: 'smooth',
    }"
    flat
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <spam v-if="cliente.situacao != 'OK'">
        <b>Inconsistências: </b>{{ cliente.situacao }}
      </spam>
      <v-col class="d-flex justify-center pl-6 pr-6">
        <v-row :key="attKey">
          <v-col
            id="importacao-clientes"
            sm="6"
            md="6"
            cols="12"
          >
            <v-switch
              v-model="cliente.cd_situac"
              dense
              inset
              :label="
                `Situação do produto: ${
                  cliente.cd_situac == 1 ? 'Ativo' : 'Inativo'
                }`
              "
            />
          </v-col>
          <v-col
            sm="6"
            md="6"
            cols="12"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Data de nascimento:"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="cliente.dt_nasccl"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancela
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(cliente.dt_nasccl)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <span class="dividerSpan line glow">Identificação do cliente</span>
            <v-row>
              <v-col
                sm="6"
                md="3"
                cols="12"
              >
                <v-select
                  v-model="cliente.tp_pessoaSelect"
                  :items="tp_pessoaItens"
                  item-text="desc"
                  item-value="id"
                  label="Tipo de pessoa"
                  persistent-hint
                  return-object
                  dense
                />
              </v-col>
              <v-col
                sm="6"
                md="3"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.nr_cpfcnpj"
                  regular
                  dense
                  label="CPF/CNPJ"
                  :rules="rules.nr_cpfcnpj"
                  :value="cliente.nr_cpfcnpj"
                />
              </v-col>
              <v-col
                sm="6"
                md="3"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.ds_insest"
                  regular
                  dense
                  label="Inscrição estadual"
                  :rules="rules.ds_insest"
                  :value="cliente.ds_insest"
                />
              </v-col>
              <v-col
                sm="6"
                md="3"
                cols="12"
              >
                <v-select
                  v-model="cliente.id_isentoSelect"
                  :items="id_isentoItens"
                  item-text="desc"
                  item-value="id"
                  label="Isento da Inscrição estadual"
                  persistent-hint
                  return-object
                  dense
                />
              </v-col>
              <v-col
                sm="6"
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.nm_client"
                  regular
                  dense
                  label="Nome do cliente"
                  :rules="rules.nm_client"
                  :value="cliente.nm_client"
                />
              </v-col>
              <v-col
                sm="6"
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.nm_fantas"
                  regular
                  dense
                  label="Nome fantasia para cliente PJ"
                  :rules="rules.nm_fantas"
                  :value="cliente.nm_fantas"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <span class="dividerSpan line glow">Endereço</span>
            <v-row>
              <v-col
                sm="6"
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.nm_end"
                  regular
                  dense
                  label="Endereço"
                  :rules="rules.nm_end"
                  :value="cliente.nm_end"
                />
              </v-col>
              <v-col
                sm="2"
                md="2"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.nr_end"
                  regular
                  dense
                  label="Número"
                  :rules="rules.nr_end"
                  :value="cliente.nr_end"
                />
              </v-col>
              <v-col
                sm="4"
                md="4"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.ds_compl"
                  regular
                  dense
                  label="Complemento"
                  :rules="rules.ds_compl"
                  :value="cliente.ds_compl"
                />
              </v-col>
              <v-col
                sm="4"
                md="4"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.nm_bairro"
                  regular
                  dense
                  label="Bairro"
                  :rules="rules.nm_bairro"
                  :value="cliente.nm_bairro"
                />
              </v-col>
              <v-col
                sm="4"
                md="4"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.nm_cidade"
                  regular
                  dense
                  label="Cidade"
                  :rules="rules.nm_cidade"
                  :value="cliente.nm_cidade"
                />
              </v-col>
              <v-col
                sm="2"
                md="2"
                cols="12"
              >
                <v-select
                  v-model="cliente.sg_estado"
                  :items="sg_estadoItens"
                  item-text="descricao"
                  item-value="sigla"
                  label="Estado"
                  persistent-hint
                  return-object
                  dense
                />
              </v-col>
              <v-col
                sm="2"
                md="2"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.nr_cep"
                  regular
                  dense
                  label="CEP"
                  :rules="rules.nr_cep"
                  :value="cliente.nr_cep"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <span class="dividerSpan line glow">Telefones</span>
            <v-row>
              <v-col
                sm="1"
                md="1"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.nr_dddtel"
                  regular
                  dense
                  label="DDD"
                  :rules="rules.nr_dddtel"
                  :value="cliente.nr_dddtel"
                />
              </v-col>
              <v-col
                sm="3"
                md="3"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.nr_tel"
                  regular
                  dense
                  label="Telefone"
                  :rules="rules.nr_tel"
                  :value="cliente.nr_tel"
                />
              </v-col>
              <v-col
                sm="1"
                md="1"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.nr_dddcel"
                  regular
                  dense
                  label="DDD"
                  :rules="rules.nr_dddcel"
                  :value="cliente.nr_dddcel"
                />
              </v-col>
              <v-col
                sm="3"
                md="3"
                cols="12"
              >
                <v-text-field
                  v-model="cliente.nr_cel"
                  regular
                  dense
                  label="Celular"
                  :rules="rules.nr_cel"
                  :value="cliente.nr_cel"
                />
              </v-col>
              <v-col
                sm="4"
                md="4"
                cols="12"
              >
                <v-switch
                  v-model="cliente.id_malad"
                  dense
                  inset
                  :label="
                    `Mala direta: ${cliente.id_malad ? 'Ativo' : 'Inativo'}`
                  "
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <span class="dividerSpan line glow">Adicionais</span>
            <v-row>
              <v-col
                sm="6"
                md="6"
                cols="12"
              >
                <v-text-field
                  id="nm_email" 
                  v-model="cliente.nm_email"
                  regular
                  dense
                  label="E-mail"
                  :rules="rules.nm_email"
                  :value="cliente.nm_email"
                />
              </v-col>
              <v-col
                sm="3"
                md="3"
                cols="12"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Data inclusão cadastro:"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      dense
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="cliente.dt_inccad"
                    no-title
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancela
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(cliente.dt_inccad)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                sm="3"
                md="3"
                cols="12"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Data última atualização:"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      dense
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="cliente.dt_atucad"
                    no-title
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancela
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(cliente.dt_atucad)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-form>
  </v-card>
  <v-card
    v-else
    flat
    :style="{
      border: $vuetify.theme.dark
        ? '1px solid #555 !important'
        : '1px solid #ddd !important',
      height: '688px',
      overflowY: 'auto',
    }"
  >
    <v-card-text :style="{ height: '100%' }">
      <div
        class="d-flex flex-column justify-end align-center"
        :style="{ height: '20%' }"
      >
        <span
          :style="{ fontSize: '1.5rem' }"
        >Selecione o cliente para editar</span>
      </div>
      <div
        class="d-flex flex-column justify-center align-center"
        :style="{ height: '80%' }"
      >
        <img
          src="/select.svg"
          :style="{ width: '60%' }"
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    clienteSelecionado: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      cliente: this.clienteSelecionado.cliente,
      valid: true,
      monetario: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      rules: {
        required: (value) => !!value || "Esse campo precisa ser preenchido.",
        // tp_pessoaSelect: [ (value) => (value != null && value != undefined && (value == 1 || value == 2) ) || "Selecione", ],
        nr_cpfcnpj: [
          (value) =>
            (value != null &&
              value != undefined &&
              value.length >= 11 &&
              value.length <= 14) ||
            "Tamanho inválido",
        ],
        // ds_insest: [
        //   (value) =>
        //     (value != null &&
        //       value != undefined &&
        //       value.length > 14) ||
        //     "Tamanho inválido",
        // ],
        nm_client: [
          (value) =>
            (value != null &&
              value != undefined &&
              value.length > 1 ) ||
            "Tamanho inválido",
        ],
        // nm_fantas: [
        //   (value) =>
        //     (value != null &&
        //       value != undefined &&
        //       value.length > 1) ||
        //     "Tamanho inválido",
        // ],
      },
      tp_pessoaItens: [
        { desc: "Física", id: "1" },
        { desc: "Jurídica", id: "2" },
      ],
      id_isentoItens: [
        { desc: "Isento", id: "1" },
        { desc: "Contribuinte", id: "2" },
        { desc: "Não contribuinte", id: "9" },
      ],
      menu: false,
      modal: false,
      menu2: false,
      sg_estadoItens: [
        { sigla: "AC", descricao: "Acre" },
        { sigla: "AL", descricao: "Alagoas" },
        { sigla: "AP", descricao: "Amapá" },
        { sigla: "AM", descricao: "Amazonas" },
        { sigla: "BA", descricao: "Bahia" },
        { sigla: "CE", descricao: "Ceará" },
        { sigla: "DF", descricao: "Distrito Federal" },
        { sigla: "ES", descricao: "Espírito Santo" },
        { sigla: "GO", descricao: "Goiás" },
        { sigla: "MA", descricao: "Maranhão" },
        { sigla: "MT", descricao: "Mato Grosso" },
        { sigla: "MS", descricao: "Mato Grosso do Sul" },
        { sigla: "MG", descricao: "Minas Gerais" },
        { sigla: "PA", descricao: "Pará" },
        { sigla: "PB", descricao: "Paraíba" },
        { sigla: "PR", descricao: "Paraná" },
        { sigla: "PE", descricao: "Pernambuco" },
        { sigla: "PI", descricao: "Piauí" },
        { sigla: "RJ", descricao: "Rio de Janeiro" },
        { sigla: "RN", descricao: "Rio Grande do Norte" },
        { sigla: "RS", descricao: "Rio Grande do Sul" },
        { sigla: "RO", descricao: "Rondônia" },
        { sigla: "RR", descricao: "Roraima" },
        { sigla: "SC", descricao: "Santa Catarina" },
        { sigla: "SP", descricao: "São Paulo" },
        { sigla: "SE", descricao: "Sergipe" },
        { sigla: "TO", descricao: "Tocantins" },
      ],
    };
  },

  beforeUpdate() {
  },

  updated() {
    this.$refs.form.validate();
  },

  created() {
  },

  methods: {
    salvar() {
      this.campos.nm_email = this.trimLower(this.campos.nm_email);
      if (this.$refs.form.validate()) {
        this.cliente = this.validaForm(this.cliente);
        this.$emit("onSalvar", {
          id: this.clienteSelecionado.id,
          cliente: this.cliente,
        });
      }
    },

    validaForm(client) {
      let itensCliente = client;
      itensCliente = this.clienteUpperCase(itensCliente);
      return itensCliente;
    },

    clienteUpperCase(items) {
      let campos = items;

      campos.nm_client = this.trimUpper(campos.nm_client);
      campos.nm_fantas = this.trimUpper(campos.nm_fantas);
      campos.nm_end = this.trimUpper(campos.nm_end);
      campos.nr_end = this.trimUpper(campos.nr_end);
      campos.ds_compl = this.trimUpper(campos.ds_compl);
      campos.nm_bairro = this.trimUpper(campos.nm_bairro);
      campos.nm_cidade = this.trimUpper(campos.nm_cidade);
      campos.sg_estado = this.trimUpper(campos.sg_estado);

      return campos;
    },

  },
};
</script>

<style>
.dividerSpan {
  display: flex;
  font-weight: bold;
}
.dividerSpan::before {
  content: "";
  flex: 1;
}
.dividerSpan::after {
  content: "";
  flex: 1;
}
.line {
  align-items: center;
  margin: 1em -1em;
}
.line::before,
.line::after {
  height: 1px;
  margin: 0 1em;
}
.glow::before,
.glow::after {
  height: 3px;
  border-radius: 5px;
}
.glow::before {
  background: linear-gradient(
    to right,
    var(--v-primary-base),
    var(--v-primary-lighten3)
  );
}
.glow::after {
  background: linear-gradient(
    to left,
    var(--v-primary-base),
    var(--v-primary-lighten3)
  );
}
#nm_email {
  text-transform: lowercase;
}
</style>

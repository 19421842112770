<template>
  <v-card
    color="transparent"
  >
    <v-card-title class="ml-2">
      Resumo da importação do lote: <span
        :style="{fontWeight: 'bold'}"
        class="pl-2"
      > {{ lote }} </span>
      <v-spacer />
      <v-btn
        color="#388e3c"
        dark
        depressed
        @click="confirmarImportacao();"
      >
        <v-icon class="pr-2">
          save
        </v-icon>
        confirmar importação
      </v-btn>
    </v-card-title>
    <v-expansion-panels
      v-model="panel"
      multiple
      flat
    >
      <v-expansion-panel :style="{background: ''}">
        <v-expansion-panel-header :style="{fontSize: '18px', fontWeight: 'bold'}">
          Identificação de lote 
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <IdentificaImportacao
            :key="attIdentificador"
            :confirmar="false"
            :dados="identificacao"
            @identificar="novaIdentificacao"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel
        class="mt-2"
      >
        <v-expansion-panel-header :style="{fontSize: '18px', fontWeight: 'bold'}">
          Clientes
        </v-expansion-panel-header>
        <v-expansion-panel-content :style="{height: '450px', overflowY: 'auto'}">
          <div>
            <v-data-table
              :headers="headers"
              :items="produtos"
              item-key="name"
              :search="pesquisar"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="pesquisar"
                  flat
                  solo
                  label="Pesquisar"
                  :background-color="$vuetify.theme.dark ? '#333' : '#eee'"
                  class="mx-4"
                />
              </template>
              <template v-slot:item.cd_situac="{ item }">
                {{ formatarSituacao(item.cd_situac) }}
              </template>
              <template v-slot:item.nr_cpfcnpj="{ item }">
                {{ formatarCPFCNPJ(item.nr_cpfcnpj) }}
              </template>
              <template v-slot:item.dt_nasccl="{ item }">
                {{ formatarData(item.dt_nasccl) }}
              </template>
            </v-data-table>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>

import IdentificaImportacao from './IdentificaImportacao.vue'
export default {
  components: {IdentificaImportacao},
  props: {
    prods: {
      type: Object,
      required: true,
      default: () => {return {}},
    },
    identificacao: {
      type: Object,
      required: false,
      default: () => { return {} },
    },
  },

  data (){
    return {
      panel: ["",1],
      lote: this.identificacao.desc,
      attIdentificador: 0,
      identfica: {},
      produtos: this.prods.dados,
      pesquisar: '',
      headers: [
        {
          text: 'Situação',
          align: 'start',
          sortable: true,
          value: 'cd_situac',
        },        
        {
          text: 'Nome',
          align: 'start',
          sortable: true,
          value: 'nm_client',
        },
        {
          text: 'CPF/CNPJ',
          align: 'start',
          sortable: true,
          value: 'nr_cpfcnpj',
        },
        {
          text: 'Dt. nasc.',
          align: 'start',
          sortable: true,
          value: 'dt_nasccl',
        },
        {
          text: 'Endereço',
          align: 'start',
          sortable: true,
          value: 'nm_end',
        },
        {
          text: 'Número',
          align: 'start',
          sortable: true,
          value: 'nr_end',
        },
        {
          text: 'Bairro',
          align: 'start',
          sortable: true,
          value: 'nm_bairro',
        },
        {
          text: 'Cidade',
          align: 'start',
          sortable: true,
          value: 'nm_cidade',
        },
        {
          text: 'UF',
          align: 'start',
          sortable: true,
          value: 'sg_estado',
        },        
        {
          text: 'CEP',
          align: 'start',
          sortable: true,
          value: 'nr_cep',
        },        
        {
          text: 'Complemento',
          align: 'start',
          sortable: true,
          value: 'ds_compl',
        },        
        
      ],
    }
  },

  watch: {
    identificacao:{
      immediate: true,
      handler(val){
        this.attIdentificador++;
      },
    },
  },

  mounted: async function () {
  },

  methods: {
    novaIdentificacao(value){
      const novo = value
      this.lote = novo.desc;
      this.$emit('nova-identificacao', novo)
    },

    confirmarImportacao() {
      this.$emit("ok");
    },

    formatarSituacao(value) {
      let status;
      switch (value) {
      case 1:
        status = 'Liberado';
        break;
      case 2:
        status = 'Pendente';
        break;
      case 3:
        status = 'Sem tradição';
        break;
      case 4:
        status = 'Vencido';
        break;
      case 5:
        status = 'Bloqueado pela operadora';
        break;
      case 6:
        status = 'Prospecção';
        break;
      case 7:
        status = 'Inativo';
        break;
      default:
        status = 'Liberado';
        // status = 'Status desconhecido';
      }
      return status;
    },

    formatarCPFCNPJ(value) {
      // Remove todos os caracteres que não são números
      value = value.replace(/\D/g, '');

      if (value.length === 11) {
        // Formatação para CPF (XXX.XXX.XXX-XX)
        return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (value.length === 14) {
        // Formatação para CNPJ (XX.XXX.XXX/XXXX-XX)
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      } else {
        // Se o valor não for nem CPF nem CNPJ, retorna o valor original
        return value;
      }
    },

    formatarData(value) {
      if (!value || value.trim() === '') {
        return value;
      } else {
        // Remove qualquer coisa que não seja dígito
        value = value.replace(/\D/g, '');

        // Verifica se o valor tem 8 dígitos (formato DDMMYYYY)
        if (value.length === 8) {
          // Retorna no formato DD/MM/YYYY
          return value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
        } else if (value.length === 10) {
          // Se o valor já estiver no formato DD/MM/YYYY, retorna como está
          return value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
        } else {
          // Se o valor não tiver o formato correto, retorna o valor original
          return value;
        }
      }
    },
  },
}
</script>

<style>

</style>
<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="80vw"
      persistent
    >
      <v-card>
        <div v-if="!transition">
          <v-card-title class="ml-1">
            Edição de clientes
            <span
              class="ml-2"
              :style="{ fontWeight: 'bolder' }"
            >
              {{ clients.id }} ({{ clients.dados.length }})
            </span>
            <v-spacer />
            <v-btn
              icon
              @click="fechar"
            >
              <v-icon color="primary">
                close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="ml-1">
            Modifique os dados dos clientes
          </v-card-subtitle>
        </div>

        <div v-else>
          <div class="d-flex justify-space-between items-center px-4 pt-4">
            <v-btn
              icon
              color="primary"
              @click="transition = !transition"
            >
              <v-icon> arrow_back </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="fechar"
            >
              <v-icon color="primary">
                close
              </v-icon>
            </v-btn>
          </div>
          <v-card-title> Edição de clientes {{ clients.id }} </v-card-title>
          <v-card-subtitle> Modifique os dados dos clientes</v-card-subtitle>
        </div>
        <v-row no-gutters>
          <v-col
            v-if="!transition"
            cols="12"
            :md="$vssWidth <= 1200 ? 12 : 5"
            sm="12"
            style="padding: 0"
          >
            <DialogList
              :clientes="clients"
              :style="{ margin: '0 10px 20px 20px' }"
              @onClickClient="ClickClient"
            />
          </v-col>
          <v-col
            v-if="$vssWidth > 1200"
            cols="12"
            md="7"
            sm="12"
            style="padding: 0"
          >
            <DialogForm
              :key="attForm"
              :style="{
                margin: '0 20px 20px 10px',
              }"
              :cliente-selecionado="clienteSelecionado"
              @onSalvar="salvar"
            />
          </v-col>
          <v-col
            v-if="transition"
            cols="12"
            :md="$vssWidth <= 1200 ? 12 : 7"
            sm="12"
            style="padding: 0"
          >
            <DialogForm
              :key="attForm"
              :style="{ margin: '0 20px 20px 10px' }"
              :cliente-selecionado="clienteSelecionado"
              @onSalvar="salvar"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DialogForm from "./DialogForm.vue";
import DialogList from "./DialogList.vue";
export default {
  components: { 
    DialogForm, 
    DialogList, 
  },

  model: {
    prop: "editClient",
    event: "update:editClient",
  },

  props: {
    editClient: {
      type: Boolean,
      required: true,
      default: false,
    },
    clientes: {
      type: [Object || Array],
      required: true,
      default: () => {
        return [];
      },
    },
    chave: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      dialog: false,
      clienteSelecionado: {},
      transition: false,
      attkey: 0,
      attForm: 0,
      clients: this.clientes,
    };
  },

  watch: {

    editClient(nv) {
      this.dialog = nv;
      this.attkey++;
    },

    dialog(newvalue) {
      this.$emit("update:editClient", newvalue);
      this.$emit("salvar");
    },

    chave(val) {
      this.attForm++;
    },

  },
  methods: {

    salvar(val) {
      const clienteEditado = val;
      this.clients.dados[clienteEditado.id] = clienteEditado.prod;
      this.$emit("cliente", clienteEditado);
    },

    ClickClient(val) {
      this.clienteSelecionado = {
        id: val,
        cliente: this.clients.dados[val],
      };
      this.clienteSelecionado.cliente.nm_email = this.trimLower( this.clienteSelecionado.cliente.nm_email );
      this.clienteSelecionado.cliente.tp_pessoaSelect = ( this.clienteSelecionado.cliente.tp_pessoa == 1 ? { desc: 'Física', id: '1' } : { desc: 'Jurídica', id: '2' } );
      this.clienteSelecionado.cliente.id_isentoSelect = ( this.clienteSelecionado.cliente.id_isento == 1 ? 
        { desc: 'Isento', id: '1' } : 
        ( this.clienteSelecionado.cliente.id_isento == 2 ? 
          { desc: 'Contribuinte', id: '2' } :
          ( this.clienteSelecionado.cliente.id_isento == 9 ? 
            { desc: 'Não contribuinte', id: '9' } : null ) ) );
      this.clienteSelecionado.cliente.sg_estado = this.getUF( this.clienteSelecionado.cliente.sg_estado);
      this.clienteSelecionado.cliente.id_malad = ( this.clienteSelecionado.cliente.id_malad == 1 ? true : false );
      
      if (this.$vssWidth <= 1200) {
        this.transition = true;
      } else {
        this.transition = false;
      }
      this.attkey++;
      this.attForm++;
    },

    fechar() {
      this.dialog = !this.dialog;
      this.clienteSelecionado = {};
      this.attkey++;
      this.attForm++;
    },
    
  },
};
</script>

<style>
</style>